import React from 'react';
import { Link } from 'react-router-dom';
import Avatar from './avatar/Avatar';
import logo from '../../images/logo1.png';
import styles from './Header.module.scss';
import Questions from '../questions/Questions';

const Header = () => (
  <header className={`${styles.header}`}>
    <Questions />
    <div className={`${styles.header__actions} flex items-center relative`}>
      <div className='flex items-center xs:justify-between lg:container lg:mx-auto md:px-12 md:w-full xs:px-8 xl:w-8/12'>
        <a href='/home'>
          <img src={logo} loading='lazy' alt='logo' />
        </a>
        <nav className={styles.nav + ' xl:w-8/12 xs:hidden lg:w-full'}>
          <ul className={styles.ul + ' flex justify-end items-center m-0 p-0 list-none'}>
            <div className='flex justify-start w-full'>
              <li className={styles.navlink + ' flex items-center'}>
                <a target='_blank' rel="noopener noreferrer" href='https://www.allofuscomms.org/cr_getstarted' className='flex items-center transition-all duration-500 ease-in-out text-white font-bold text-sm no-underline uppercase'>Get started</a>
              </li>
              <li className={styles.navlink + ' flex items-center'}>
                {/* <Link to='/browsing-mode' className='flex items-center transition-all duration-500 ease-in-out text-white font-bold text-sm no-underline uppercase'>Browse</Link> */}


                <div className={`flex items-center transition-all duration-500 ease-in-out text-white font-bold text-sm no-underline uppercase ${styles.menu}`}>
                    <ul className={`flex items-center transition-all duration-500 ease-in-out text-white font-bold text-sm no-underline uppercase`}>
                        <li>
                        Browse
                            <ul>
                                <li>Assets
                                    <ul className={styles.language}>
                                        <li>English
                                            <ul>
                                                <li className={styles.link}><a href="/search-results?search=Become Our Partner Flyer&langcode=en">Become Our Partner<br></br>Flyer</a></li>
                                                <li className={styles.link + ' whitespace-nowrap'}><a href="/search-results?search=diversity fact sheet&langcode=en">Diversity Fact Sheets</a></li>
                                                <li className={styles.link}><a href="/search-results?search=ehr fact sheet&langcode=en">EHR Fact Sheet</a></li>
                                                <li className={styles.link}><a href="/search-results?search=General Brochure&langcode=en">General Brochure</a></li>
                                                <li className={styles.link}><a href="/search-results?search=Genomics 101 Deck&langcode=en">Genomics 101 Deck</a></li>
                                                <li className={styles.link}><a href="/search-results?search=How to Join Poster&langcode=en">How to Join Poster</a></li>
                                                <li className={styles.link}><a href="/search-results?search=infographic flyer&langcode=en">Infographic Flyer</a></li>
                                                <li className={styles.link}><a href="/search-results?search=privacy fact sheet&langcode=en">Privacy Fact Sheet</a></li>
                                                <li className={styles.link + ' whitespace-nowrap'}><a href="/search-results?search=Program Brochure&langcode=en">Program Brochure</a></li>
                                                <li className={styles.link}><a href="/search-results?search=Program faq&langcode=en">Program FAQ<br></br>Fact Sheet</a></li>
                                            </ul>
                                        </li>
                                        <li>Spanish
                                            <ul>
                                                <li className={styles.link}><a href="/search-results?search=Become Our Partner Flyer&langcode=es">Become Our Partner<br></br>Flyer</a></li>
                                                <li className={styles.link + ' whitespace-nowrap'}><a href="/search-results?search=diversity fact sheet&langcode=es">Diversity Fact Sheets</a></li>
                                                <li className={styles.link}><a href="/search-results?search=ehr fact sheet&langcode=es">EHR Fact Sheet</a></li>
                                                <li className={styles.link}><a href="/search-results?search=General Brochure&langcode=es">General Brochure</a></li>
                                                <li className={styles.link}><a href="/search-results?search=Genomics 101 Deck&langcode=es">Genomics 101 Deck</a></li>
                                                <li className={styles.link}><a href="/search-results?search=How to Join Poster&langcode=es">How to Join Poster</a></li>
                                                <li className={styles.link}><a href="/search-results?search=infographic flyer&langcode=es">Infographic Flyer</a></li>
                                                <li className={styles.link}><a href="/search-results?search=privacy fact sheet&langcode=es">Privacy Fact Sheet</a></li>
                                                <li className={styles.link + ' whitespace-nowrap'}><a href="/search-results?search=Program Brochure&langcode=es">Program Brochure</a></li>
                                                <li className={styles.link}><a href="/search-results?search=Program faq&langcode=es">Program FAQ<br></br>Fact Sheet</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                    </li>
                                    <li>Audience
                                    <ul className={styles.language}>
                                        <li>English
                                            <ul>    
                                                <li className={styles.link}><a href="/search-results?search=%20&audience=enrolled%20participants&langcode=en">Enrolled Participants</a></li>
                                                <li className={styles.link}><a href="/search-results?search=%20&audience=potential%20participants&langcode=en">Potential Participants</a></li>
                                                <li className={styles.link}><a href="/search-results?search=%20&audience=providers&langcode=en">Providers</a></li>
                                            </ul>
                                        </li>
                                        <li>Spanish
                                            <ul>    
                                                <li className={styles.link}><a href="/search-results?search=%20&audience=enrolled%20participants&langcode=es">Enrolled Participants</a></li>
                                                <li className={styles.link}><a href="/search-results?search=%20&audience=potential%20participants&langcode=es">Potential Participants</a></li>
                                                <li className={styles.link}><a href="/search-results?search=%20&audience=providers&langcode=es">Providers</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                    </li>
                                    <li>Campaigns
                                    <ul className={styles.language}>
                                        <li>English
                                            <ul>
                                                <li className={styles.link}><a href="/search-results?search=health care professionals campaign&langcode=en">Health Care Professionals Campaign</a></li>
                                                <li className={styles.link}><a href="/search-results?search=holiday card&langcode=en">Holiday Campaign</a></li>
                                                <li className={styles.link}><a href="/search-results?search=nursing campaign&langcode=en">Nursing Campaign</a></li>
                                                <li className={styles.link}><a href="/search-results?search=physician campaign&langcode=en">Physician Campaign</a></li>
                                                <li className={styles.link}><a href="/search-results?search=c16&langcode=en">Research Insights Drop-in</a></li>
                                                <li className={styles.link}><a href="/search-results?search=share your story&langcode=en">“Share Your Story” Campaign</a></li>
                                                <li className={styles.link}><a href="/search-results?search=strength in numbers&langcode=en">“Strength in Numbers” Campaign</a></li>
                                                <li>Value Proposition
                                                    <ul className={styles.theme}>
                                                        <li className={styles.link}><a href="/search-results?search=value proposition theme 1 - you're the key&langcode=en">Messaging Matrix Theme 1</a></li>
                                                        <li className={styles.link}><a href="/search-results?search=value proposition theme 2 - get health information that matters&langcode=en">Messaging Matrix Theme 2</a></li>
                                                        <li className={styles.link}><a href="/search-results?search=value proposition theme 3 - today's research may lead to tomorrow's treatment&langcode=en">Messaging Matrix Theme 3</a></li>
                                                        <li className={styles.link}><a href="/search-results?search=value proposition&copy_type=cta&langcode=en">CTA<span className='lowercase'>s</span></a></li>
                                                        <li className={styles.link}><a href="/search-results?search=general theme&langcode=en">General Messaging Assets</a></li>
                                                        <li className={styles.link}><a href="/search-results?search=community theme&langcode=en">Community Theme Assets</a></li>
                                                        <li className={styles.link}><a href="/search-results?search=genomics theme&langcode=en">Genomics Theme Assets</a></li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>Spanish
                                            <ul>
                                                <li className={styles.link}><a href="/search-results?search=health care professionals campaign&langcode=es">Health Care Professionals Campaign</a></li>
                                                <li className={styles.link}><a href="/search-results?search=holiday card&langcode=es">Holiday Campaign</a></li>
                                                <li className={styles.link}><a href="/search-results?search=nursing campaign&langcode=es">Nursing Campaign</a></li>
                                                <li className={styles.link}><a href="/search-results?search=physician campaign&langcode=es">Physician Campaign</a></li>
                                                <li className={styles.link}><a href="/search-results?search=c16&langcode=es">Research Insights Drop-in</a></li>
                                                <li className={styles.link}><a href="/search-results?search=share your story&langcode=es">“Share Your Story” Campaign</a></li>
                                                <li className={styles.link}><a href="/search-results?search=strength in numbers&langcode=es">“Strength in Numbers” Campaign</a></li>
                                                <li>Value Proposition
                                                    <ul className={styles.theme}>
                                                        <li className={styles.link}><a href="/search-results?search=value proposition theme 1 - you're the key&langcode=es">Messaging Matrix Theme 1</a></li>
                                                        <li className={styles.link}><a href="/search-results?search=value proposition theme 2 - get health information that matters&langcode=es">Messaging Matrix Theme 2</a></li>
                                                        <li className={styles.link}><a href="/search-results?search=value proposition theme 3 - today's research may lead to tomorrow's treatment&langcode=es">Messaging Matrix Theme 3</a></li>
                                                        <li className={styles.link}><a href="/search-results?search=value proposition&copy_type=cta&langcode=es">CTA<span className='lowercase'>s</span></a></li>
                                                        <li className={styles.link}><a href="/search-results?search=general theme&langcode=es">General Messaging Assets</a></li>
                                                        <li className={styles.link}><a href="/search-results?search=community theme&langcode=es">Community Theme Assets</a></li>
                                                        <li className={styles.link}><a href="/search-results?search=genomics theme&langcode=es">Genomics Theme Assets</a></li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                    </li>
                                    <li>Copy Type
                                    <ul className={styles.language}>
                                        <li>English
                                            <ul>
                                                <li className={styles.link}><a href="/search-results?search=%20&copy_type=body%20copy&langcode=en" className={styles.anchor}>Body Copy</a></li>
                                                <li className={styles.link}><a href="/search-results?search=%20&copy_type=cta&langcode=en" className={styles.anchor}>CTA<span className='lowercase'>s</span></a></li>
                                                <li className={styles.link}><a href="/search-results?search=%20&copy_type=headline&langcode=en" className={styles.anchor}>Headlines</a></li>
                                                <li className={styles.link}><a href="/search-results?search=%20&copy_type=q%26a&langcode=en" className={styles.anchor}>Q&A<span className='lowercase'>s</span></a></li>
                                                <li className={styles.link}><a href="/search-results?search=%20&copy_type=quotation&langcode=en" className={styles.anchor}>Quotations</a></li>
                                                <li className={styles.link}><a href="/search-results?search=%20&copy_type=scripts&langcode=en" className={styles.anchor}>Scripts</a></li>
                                            </ul>
                                        </li>
                                        <li>Spanish
                                            <ul>
                                                <li className={styles.link}><a href="/search-results?search=%20&copy_type=body%20copy&langcode=es" className={styles.anchor}>Body Copy</a></li>
                                                <li className={styles.link}><a href="/search-results?search=%20&copy_type=cta&langcode=es" className={styles.anchor}>CTA<span className='lowercase'>s</span></a></li>
                                                <li className={styles.link}><a href="/search-results?search=%20&copy_type=headline&langcode=es" className={styles.anchor}>Headlines</a></li>
                                                <li className={styles.link}><a href="/search-results?search=%20&copy_type=q%26a&langcode=es" className={styles.anchor}>Q&A<span className='lowercase'>s</span></a></li>
                                                <li className={styles.link}><a href="/search-results?search=%20&copy_type=quotation&langcode=es" className={styles.anchor}>Quotations</a></li>
                                                <li className={styles.link}><a href="/search-results?search=%20&copy_type=scripts&langcode=es" className={styles.anchor}>Scripts</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                    </li>
                                    <li>Proposed Usage
                                        <ul className={styles.language}>
                                            <li>English
                                                <ul> 
                                                    <li className={styles.link}><a href="/search-results?search=%20&proposed_usage=email&langcode=en" className={styles.anchor}>Email</a></li>
                                                    <li className={styles.link}><a href="/search-results?search=%20&proposed_usage=paid%20media&langcode=en" className={styles.anchor}>Paid Media</a></li>
                                                    <li className={styles.link}><a href="/search-results?search=%20&proposed_usage=phone%20%26%20voicemail%20scripts&langcode=en" className={styles.anchor}>Phone & Voicemail Scripts</a></li>
                                                    <li className={styles.link}><a href="/search-results?search=%20&proposed_usage=print%20%26%20direct%20mail&langcode=en" className={styles.anchor}>Print & Direct Mail</a></li>
                                                    <li className={styles.link}><a href="/search-results?search=%20&proposed_usage=sms%20text%20messaging&langcode=en" className={styles.anchor}>SMS Text Messaging</a></li>
                                                    <li className={styles.link}><a href="/search-results?search=%20&proposed_usage=social%20media&langcode=en" className={styles.anchor}>Social Media</a></li>
                                                    <li className={styles.link}><a href="/search-results?search=testimonial&langcode=en" className={styles.anchor}>Testimonials</a></li>
                                                    <li className={styles.link}><a href="/search-results?search=%20&proposed_usage=video%20%26%20audio%20scripts&langcode=en" className={styles.anchor}>Video & Audio Scripts</a></li>
                                                    <li className={styles.link}><a href="/search-results?search=%20&proposed_usage=webpage&langcode=en" className={styles.anchor}>Webpage</a></li>
                                                </ul>
                                            </li>
                                            <li>Spanish
                                                <ul> 
                                                    <li className={styles.link}><a href="/search-results?search=%20&proposed_usage=email&langcode=es" className={styles.anchor}>Email</a></li>
                                                    <li className={styles.link}><a href="/search-results?search=%20&proposed_usage=paid%20media&langcode=es" className={styles.anchor}>Paid Media</a></li>
                                                    <li className={styles.link}><a href="/search-results?search=%20&proposed_usage=phone%20%26%20voicemail%20scripts&langcode=es" className={styles.anchor}>Phone & Voicemail Scripts</a></li>
                                                    <li className={styles.link}><a href="/search-results?search=%20&proposed_usage=print%20%26%20direct%20mail&langcode=es" className={styles.anchor}>Print & Direct Mail</a></li>
                                                    <li className={styles.link}><a href="/search-results?search=%20&proposed_usage=sms%20text%20messaging&langcode=es" className={styles.anchor}>SMS Text Messaging</a></li>
                                                    <li className={styles.link}><a href="/search-results?search=%20&proposed_usage=social%20media&langcode=es" className={styles.anchor}>Social Media</a></li>
                                                    <li className={styles.link}><a href="/search-results?search=testimonial&langcode=es" className={styles.anchor}>Testimonials</a></li>
                                                    <li className={styles.link}><a href="/search-results?search=%20&proposed_usage=video%20%26%20audio%20scripts&langcode=es" className={styles.anchor}>Video & Audio Scripts</a></li>
                                                    <li className={styles.link}><a href="/search-results?search=%20&proposed_usage=webpage&langcode=es" className={styles.anchor}>Webpage</a></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>Toolkits & Topics
                                    <ul className={styles.language}>
                                        <li>English
                                            <ul>
                                                <li className={styles.link}><a href="/search-results?search=account creation&langcode=en" className={styles.anchor}>Account Creation</a></li>
                                                <li className={styles.link}><a href="/search-results?search=Diversity&langcode=en" className={styles.anchor}>Diversity</a></li>
                                                <li>Genomics
                                                    <ul className={styles.theme}>
                                                        <li className={styles.link}><a href="/search-results?search=gem&langcode=en" className={styles.anchor}>GEM Assets</a></li>
                                                        <li className={styles.link}><a href="/search-results?search=general genomics assets&langcode=en" className={styles.anchor}>General Genomics Assets</a></li>
                                                        <li className={styles.link}><a href="/search-results?search=ghr3&langcode=en" className={styles.anchor}>GHR3 Assets</a></li>
                                                    </ul>
                                                </li>
                                                <li className={styles.link}><a href="/search-results?search=Health Disparities&langcode=en" className={styles.anchor}>Health Disparities</a></li>
                                                <li className={styles.link}><a href="/search-results?search=LGBTQIA+&langcode=en" className={styles.anchor}>LGBTQIA+</a></li>
                                                <li className={styles.link}><a href="/search-results?search=Physical Measurements&langcode=en" className={styles.anchor}>Physical Measurements</a></li>
                                                <li className={styles.link}><a href="/search-results?search=Privacy&langcode=en" className={styles.anchor}>Privacy</a></li>
                                                <li className={styles.link}><a href="/search-results?search=refer a friend&langcode=en" className={styles.anchor}>Refer-A-Friend</a></li>
                                                <li className={styles.link}><a href="/search-results?search=security&langcode=en" className={styles.anchor}>Security</a></li>
                                                <li className={styles.link}><a href="/search-results?search=Testimonials&langcode=en" className={styles.anchor}>Testimonials</a></li>
                                                <li className={styles.link}><a href="/search-results?search=Trust&langcode=en" className={styles.anchor}>Trust</a></li>
                                            </ul>
                                        </li>
                                        <li>Spanish
                                            <ul>
                                                <li className={styles.link}><a href="/search-results?search=account creation&langcode=es" className={styles.anchor}>Account Creation</a></li>
                                                <li className={styles.link}><a href="/search-results?search=Diversity&langcode=es" className={styles.anchor}>Diversity</a></li>
                                                <li>Genomics
                                                    <ul className={styles.theme}>
                                                        <li className={styles.link}><a href="/search-results?search=gem&langcode=es" className={styles.anchor}>GEM Assets</a></li>
                                                        <li className={styles.link}><a href="/search-results?search=general genomics assets&langcode=es" className={styles.anchor}>General Genomics Assets</a></li>
                                                        <li className={styles.link}><a href="/search-results?search=ghr3&langcode=es" className={styles.anchor}>GHR3 Assets</a></li>
                                                    </ul>
                                                </li>
                                                <li className={styles.link}><a href="/search-results?search=Health Disparities&langcode=es" className={styles.anchor}>Health Disparities</a></li>
                                                <li className={styles.link}><a href="/search-results?search=LGBTQIA+&langcode=es" className={styles.anchor}>LGBTQIA+</a></li>
                                                <li className={styles.link}><a href="/search-results?search=Physical Measurements&langcode=es" className={styles.anchor}>Physical Measurements</a></li>
                                                <li className={styles.link}><a href="/search-results?search=Privacy&langcode=es" className={styles.anchor}>Privacy</a></li>
                                                <li className={styles.link}><a href="/search-results?search=refer a friend&langcode=es" className={styles.anchor}>Refer-A-Friend</a></li>
                                                <li className={styles.link}><a href="/search-results?search=security&langcode=es" className={styles.anchor}>Security</a></li>
                                                <li className={styles.link}><a href="/search-results?search=testimonial&langcode=es" className={styles.anchor}>Testimonials</a></li>
                                                <li className={styles.link}><a href="/search-results?search=Trust&langcode=es" className={styles.anchor}>Trust</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>

              </li>
              <li className={styles.navlink + ' flex items-center'}>
                <Link to='/favorites' className='flex items-center transition-all duration-500 ease-in-out text-white font-bold text-sm no-underline uppercase'>My Favorites</Link>
              </li>
              <li className={styles.navlink + ' flex items-center'}>
                <a href='//allofuscomms.org' className='flex items-center transition-all duration-500 ease-in-out text-white font-bold text-sm no-underline uppercase'>Comms Portal</a>
              </li>
            </div>
          </ul>
        </nav>
        <div className='flex xl:w-2/12 justify-end'>
          <Avatar />
        </div>
      </div>
    </div>
    <div className={styles.header__green__divider + ' w-full'} />
  </header>
)

export default Header;
